<template>
    <Head v-if="person">
      <title>
        {{getTitle()}} - {{websiteTitle}}
      </title>
      <meta name="description" :content="getTitle() + '.' + getOccupation(person)" />
    </Head>
    <tabs v-if="person" :activeTab="'Antavla'"/>
    <article class="dragscroll">
      <div id="gentree" v-if="person" :style="{transform: 'scale(' + 0.01 * zoom + ')'}">
        <div class="branch start">
        <person-tree-node :maxGen="parseInt(generations)" :person="person" :ancestors="ancestors" :root="true" />
        </div>
      </div>
      <div class="slider">
        <label for="genrange" id="gentext">Antal generationer: {{generations}}</label>
        <input id="genrange" type="range" min="1" :max="maxGenerations" v-model="generations" @change="updateGeneration">
        <label for="genzoom">Zoom: {{zoom}}%</label>
        <input id="genzoom" type="range" min="10" max="150" v-model="zoom">
      </div>
      <div v-if="loader" class="loader" :style="{
        margin: '0 auto',
        borderRadius: '100%',
        border: '3px solid #eee',
        borderTop: '3px solid ' + color,
        width: '50px',
        height: '50px',
        animation: 'spinner 0.8s linear infinite',
        position: 'fixed',
        top: '50%',
        left: '50%'}">
      </div>
    </article>
</template>

<script>
import PersonTreeNode from './subcomponents/PersonTreeNode.vue'
import dragscroll from '../assets/js/dragscroll'
import RenderPersonTitle from '../mixins/renderPersonTitle'
import Fetch from '../mixins/fetch'

export default {
  mixins: [
    RenderPersonTitle,
    Fetch
  ],
  components: {
    PersonTreeNode
  },
  setup () {
    const context = document.createElement('canvas').getContext('2d')
    const font = 'px ' + window.getComputedStyle(document.getElementById('app')).getPropertyValue('font-family')
    return {
      context,
      font
    }
  },
  data () {
    return {
      person: null,
      ancestors: null,
      generations: this.$route.query.gen || 3,
      isChangingGen: false,
      loader: false,
      maxGenerations: process.env.VUE_APP_MAX_GEN,
      color: process.env.VUE_APP_MASTER_COLOR,
      zoom: 100
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => vm.getPerson(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPerson(to)
    next()
  },
  methods: {
    getTitle () {
      return 'Antavla för ' + this.getName(this.person, true, true)
    },
    updateGeneration () {
      this.loader = true
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, gen: this.generations } })
    },
    getPerson (to) {
      this.fetchData('/persons/' + this.pPop(to.params.person) + '/a?generations=' + (1 + parseInt(to.query.gen || 3))).then(data => {
        dragscroll.reset()
        if (data.Ancestors) {
          data.Ancestor = data.Gen = 0
          this.generateData(data, '')
          this.ancestors = data.Ancestors.reduce((acc, value) => {
            if (!acc[value.Gen]) {
              acc[value.Gen] = []
            }
            acc[value.Gen].push(value)
            this.generateData(value, 'Father')
            this.generateData(value, 'Mother')
            return acc
          }, {})
        }
        this.person = data
        this.loader = false
      })
    },
    generateData (element, parent) {
      const rows = [
        [element[parent + 'FirstName'], element[parent + 'LastName']],
        ['*', element[parent + 'BirthString'], this.pReverse(element[parent + 'BirthPlace'] || '')],
        ['†', element[parent + 'DeathString'], this.pReverse(element[parent + 'DeathPlace'] || '')],
        [element[parent + 'Occupation']],
        [element[parent + 'Age']]
      ]
      const gen = +element.Gen + 1
      for (let i = 0; i < rows.length; i++) {
        rows[i] = rows[i].join(' ').trim()
        if (rows[i].length <= 1) rows[i] = '&nbsp'
        if (i === 0) {
          rows[i] = this.getNameAsHhtml(rows[i])
          this.context.font = '12' + this.font
        } else {
          this.context.font = '10' + this.font
        }
        this.treeWidths[gen] = Math.max(this.treeWidths[gen] || 0, 50 + parseInt(this.context.measureText(rows[i]).width))
        this.treeData[element[parent + 'Id']] = rows
      }
    }
  }
}
</script>

<style lang="scss">
.loader {
  transition: all 0.3s linear;
  z-index: 2;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dragscroll {
  cursor: grab
}

#gentree {
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }
  transform-origin: 0 0;
  max-height: 100%
}

div.slider {
  label {
    margin-bottom:5px;
    display:block
  }
  position: fixed;
  bottom:2rem
}

input[type=range] {
  padding: .375rem 0rem;
  -webkit-appearance: none;
  height: 1px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: $MASTER_COLOR;
  cursor: pointer
}

input[type=range]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $MASTER_COLOR;
  cursor: pointer
}
</style>
