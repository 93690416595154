import { createRouter, createWebHistory } from 'vue-router'
import Person from '../components/Person'
import Place from '../components/Place'
import Static from '../components/Static'
import Search from '../components/Search'
import Contact from '../components/Contact'
import PersonTree from '../components/PersonTree'

const routes = [
  { path: '/kontakt', component: Contact },
  { path: '/:person(.*Antavla)', component: PersonTree },
  { path: '/:person(.*Media)', component: Person, meta: { activeTab: 'Media' } },
  { path: '/:person(.*Karta)', component: Place },
  { path: '/:person(.*-[a-z0-9]*)', component: Person, meta: { activeTab: 'Person' } },
  { path: '/platser/:place(.*)?', component: Place },
  { path: '/personer', component: Search },
  { path: '/', component: Place },
  { path: '/om', component: Static, meta: { title: 'Om', description: 'Om min forskning' } },
  { path: '/brandt', component: Static, meta: { title: 'Brandtsläkten', description: 'Information om släkten Brandt' } },
  { path: '/nyheter', component: Static, meta: { title: 'Nyheter', description: 'Nyheter i min forskning' } }
]

if (process.env.VUE_APP_ID === '2') {
  routes.push({ path: '/dombocker', component: Static, meta: { title: 'Domböcker', description: 'Domböcker' } }
  )
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
