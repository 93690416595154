<template>
  <Head>
    <title>{{title}}</title>
    <meta name="description" :content="description"/>
  </Head>
    <tabs v-if="searchResult" :activeTab="activeTab" />
    <article ref="article">
        <h1 v-if="searchResult">Sökresultat för '{{q}}'</h1>
        <table v-if="searchResult && searchResult.persons">
            <thead>
                <tr>
                    <th @click="sortBy('firstname')" :class="sortClass('firstname')">Förnamn</th>
                    <th @click="sortBy('lastname')" :class="sortClass('lastname')">Efternamn</th>
                    <th @click="sortBy('birthdate')" :class="sortClass('birthdate')">Födelsedatum</th>
                    <th @click="sortBy('birthplace')" :class="sortClass('birthplace')">Födelseort</th>
                    <th @click="sortBy('deathdate')" :class="sortClass('deathdate')">Dödsdatum</th>
                    <th @click="sortBy('deathplace')" :class="sortClass('deathplace')">Dödsort</th>
                </tr>
            </thead>
            <tr v-for="person in searchResult.persons" :key="person">
                <td><person-link :person="person" :showPeriod="false" v-html="getNameAsHhtml(person.FirstName)"></person-link></td>
                <td><person-link :person="person" :showPeriod="false">{{person.LastName}}</person-link></td>
                <td><span>Född </span>{{person.BirthString}}</td>
                <td><place-name :placeName="person.BirthPlace" :individualLinks="true"/></td>
                <td><span>Död </span>{{person.DeathString}}</td>
                <td><place-name :placeName="person.DeathPlace" :individualLinks="true"/></td>
            </tr>
        </table>
        <p v-else-if="searchResult">Inga träffar...</p>
    </article>
</template>
<script>
import Fetch from '../mixins/fetch'
import RenderPersonTitle from '../mixins/renderPersonTitle'
export default {
  mixins: [
    Fetch,
    RenderPersonTitle
  ],
  data () {
    return {
      searchResult: null,
      limit: 50,
      page: 1,
      sort: '',
      direction: 'asc',
      q: null,
      activeTab: 'Persons',
      searching: false,
      title: null,
      description: this.websiteTitle
    }
  },
  methods: {
    sortClass (sortBy) {
      return this.sort === sortBy ? this.direction : ''
    },
    sortBy (sort) {
      if (this.sort === sort) {
        if (this.direction === 'asc') {
          this.direction = 'desc'
        } else if (this.direction === 'desc') {
          this.sort = ''
        } else if (this.direction === '') {
          this.direction = 'asc'
        }
      } else {
        this.sort = sort
        this.direction = 'asc'
      }
      if (this.sort) {
        this.$router.push({ path: '/personer', query: { q: this.q, sort: sort, direction: this.direction } })
      } else {
        this.$router.push({ path: '/personer', query: { q: this.q } })
      }
    },
    goToPlaces () {
      this.$router.push({ path: '/platser', query: { ...this.$route.query } })
    },
    onScroll () {
      const article = this.$refs.article
      if ((article.scrollTop / (article.scrollHeight - article.clientHeight)) > 0.8) {
        if (!this.searching) {
          this.page += 1
        }
        this.search(true)
      }
    },
    search (append) {
      if (!this.searching && this.q && this.q.length > 0) {
        this.searching = true
        this.fetchData('/search?q=' + this.q + '&l=' + this.limit + '&page=' + this.page + '&sort=' + this.sort + '&direction=' + this.direction).then(data => {
          this.searching = false
          this.title = 'Sökresultat för ' + this.q
          this.description = this.title
          this.title += ' - ' + this.websiteTitle
          if (!this.searchResult) {
            this.searchResult = data
          } else {
            if (data.persons) {
              this.searchResult.persons = this.searchResult.persons && append ? this.searchResult.persons.concat(data.persons) : data.persons
            }
            if (data.places) {
              this.searchResult.places = this.searchResult.places && append ? this.searchResult.places.concat(data.places) : data.places
            }
          }
        })
      }
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      vm.q = to.query.q
      vm.sort = to.query.sort || ''
      vm.direction = to.query.direction || 'asc'
      vm.search()
      vm.$refs.article.addEventListener('scroll', vm.onScroll, { passive: true })
    })
  },
  beforeRouteUpdate (to, _from, next) {
    this.q = to.query.q
    this.sort = to.query.sort || ''
    this.direction = to.query.direction || 'asc'
    this.search(false)
    next()
  },
  beforeUnmount () {
    this.$refs.article.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss" scoped>
table {
    th {
        color: $MASTER_COLOR;
        text-align: left;
    }
    td {
      font-style: normal;
        min-width: inherit;
        font-weight: normal;
        font-size: 1rem;
        width:inherit;
        color: $MASTER_COLOR;
        padding: 0.5rem;
        span {
          display:none
        }
        @media (max-width: 992px) {
        &:first-child,&:nth-child(2) {
          display:inline;
          padding-right: 0;
          padding-left: 0.5rem;
        }
          span {
            display:inline;
          }
        }
    }
    tr:nth-child(even) {
        background-color: lighten($MASTER_COLOR, 55%);
    }
    tr:first-child {
        @media (max-width: 992px) {
            display:none
        }
        th {
            padding: 0.5em;
            color: white;
            cursor: pointer;
            &.asc:after {
              content: '\25B4'
            }
            &.desc:after {
              content: '\25BE'
            }
        }
        background-color: $MASTER_COLOR;
    }
    width:100%
}
ul {
    display: flex;
    height: 2.35rem;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
    margin: 0px;
    a {
        @media (max-width: 767px) {
            padding: 0.5rem 0.5rem
        }
        &.active {
            color: #495057;
            border-color: #dee2e6 #dee2e6 #fff
        }
        &:hover {
            border-color: #e9ecef #e9ecef #dee2e6
        }
        font-weight: 400;
        text-decoration: none;
        display:flex;
        border: 1px solid transparent;
        padding: .5rem 1rem;
        cursor:pointer
    }
}
</style>
